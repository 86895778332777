/* Reset basic styles */
body, h1, h2, h3, p, ul, ol, form, select, input, button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  color: #000;
  line-height: 1.6;
}

/* Container for the app */
.App {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

/* Logo container */
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logo {
  width: 120px;
  height: auto;
}

/* Form container */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Form groups */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  text-align: left;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-control {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Button styles */
.btn {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #0033cc; /* Primary color */
  color: #fff;
}

.btn-primary:hover {
  background-color: #002299;
}

.btn-primary:disabled {
  background-color: #b0d4ff;
  cursor: not-allowed;
}

/* Error message */
.alert {
  padding: 15px;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 4px;
  margin-top: 15px;
}

.alert p {
  margin: 0;
}

/* Result container */
.result-container {
  margin-top: 30px;
  background-color: #e9ecef;
  padding: 20px;
  border-radius: 4px;
}

.result-container h2 {
  font-size: 24px;
  color: #0033cc; /* Brand color */
  margin-bottom: 15px;
}

.result-container p {
  font-size: 18px;
  margin-bottom: 10px;
}

.info-text {
  font-size: 16px;
  margin-top: 10px; /* Espaço acima do texto */
  margin-bottom: 30px; /* Espaço abaixo do texto (aumente para mais espaço) */
  color: #333; /* Cor do texto */
}
